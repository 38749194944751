import styled from 'styled-components';

export const MobileScrollableWrap = styled.div`
  /* Copy from Airbnb */
  display: flex;
  margin-top: 0px;
  padding-top: 0.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -1.5rem;
  margin-right: 0;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: initial, initial, initial;
  transition-delay: initial, initial, initial;
  transition-property: -ms-transform, transform, transform;
  scroll-behavior: smooth;
  white-space: nowrap;
  overflow-x: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch !important;
`;

export const MobileScrollableItem = styled.div`
  padding: 0 8px;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
`;

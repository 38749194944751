import React, {
  useState, useMemo, useCallback, useEffect, useRef
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Input from '@material-ui/core/Input';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { sendEvent } from 'utils/gtag';
import { ClearIcon, SearchIcon } from 'components/StyledIcons/StyledIcons';
import { makeStyles } from '@material-ui/core/styles';
import useSearchState from 'hooks/useSearchState';
import raf from 'raf';
import { media } from '../../theme/styled-theme';

export const POSITION = {
  NAVIGATION: 'Navigation',
  HOME_BANNER: 'Home Banner',
  HOME_BANNER_BOTTOM: 'Home Banner Bottom',
};

/* -------------------------------------------------------------------------- */
/*                             Material UI Classes                            */
/* -------------------------------------------------------------------------- */
const useInputStyles = makeStyles({
  root: {
    flex: 1,
    width: '93%',
  }
});

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

/* eslint-disable indent */
const Root = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid ${props => props.isActive ? '#00b33b' : '#b4b4b4'};
  height: ${props => props.navigation ? '2.625rem' : '3rem'};
  padding: 0.875rem 3.25rem 0.875rem 1rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: ${props => {
    const defaultShadow = props.navigation ? '1px 2px 8px 1px rgba(0,0,0,0.12)' : '';
    return props.isActive ? `${defaultShadow}, inset 1px 1px 3px 0 rgba(4, 132, 75, 0.3)` : defaultShadow;
  }};

  ${media.forPhoneOnly`
    padding: 0.875rem 1rem;
  `}

  ${media.forTabletLandscapeUp`
    max-width: ${
      props => props.position === POSITION.HOME_BANNER
      || props.position === POSITION.NAVIGATION ? '360px' : 'inherit'
    };
  `}
`;
/* eslint-enable indent */

const Dropdown = styled.div`
  background: #FFFFFF;
  box-shadow: 1px 4px 7px 0 rgba(0,0,0,0.28);
  border-radius: 4px;
  width: ${props => props.width ? `${props.width}px` : '100%'};
  margin-top: 0.25rem;
  max-width: ${props => props.position === POSITION.HOME_BANNER ? '360px' : 'inherit'};
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const SearchBox = props => {
  const {
    navigation,
    placeholder,
    listEl,
    scrollIntoView,
    position,
    history,
    pathname,
    disableList
  } = props;
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { query } = useSearchState();
  const [keyword, setKeyword] = useState(query);
  const [dropdownWidth, setDropdownWidth] = useState();
  const inputClasses = useInputStyles();

  const open = Boolean(anchorEl);

  /* ------------------------------ effect hooks ------------------------------ */
  useEffect(() => {
    if (open) {
      raf(() => inputRef.current.focus());
    }
  }, [open]);

  useEffect(() => {
    if (!scrollIntoView) return;
    if (!open) return;
    if (typeof window === 'undefined') return;
    // if (window.innerWidth >= 600) return; // only scroll into view when in mobile size

    const { top } = anchorEl.getBoundingClientRect();
    raf(() => {
      window.scroll({
        top: window.scrollY + top - 80,
        behavior: 'smooth',
      });
    });
  }, [scrollIntoView, open]);

  /* --------------------------- handlers and memos --------------------------- */
  const popperConfig = useMemo(() => ({
    flip: {
      enabled: true,
    },
    preventOverflow: {
      enabled: true,
      boundariesElement: 'scrollParent',
      padding: 0,
    },
    arrow: {
      enabled: false,
    },
  }), []);

  const clearIconStyle = useMemo(() => ({
    root: {
      display: open ? 'none' : 'flex',
      width: '1.25rem',
      height: '1.25rem',
      color: '#343434',
      right: '2.25rem',
      position: 'absolute',
      background: 'white'
    }
  }), [open]);

  const searchIconStyle = useMemo(() => ({
    root: {
      display: 'flex',
      width: '1.5rem',
      height: '1.5rem',
      color: keyword ? '#00b33b' : '#343434',
      right: '0.5rem',
      position: 'absolute',
      background: 'white'
    }
  }), [keyword]);

  const onClickHandler = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setAnchorEl(anchorEl ? null : e.currentTarget);
      setDropdownWidth(dropdownWidth || e.currentTarget.offsetWidth);
      sendEvent({ action: `Open Searchbox on ${position}`, category: 'engagement' });
    },
    [anchorEl, position],
  );

  const handleClickAway = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    setTimeout(() => setAnchorEl(null), 0); // prevet calling it before onClickHandler
    sendEvent({ action: 'Close Searchbox', category: 'engagement' });
  }, []);

  const onInputChange = useCallback(e => {
    setKeyword(e.target.value);
  }, []);

  const onClearTextClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => history.push('/search'), 0);
    sendEvent({ action: 'Clear Search Keyword', category: 'engagement', label: keyword });
    setKeyword(''); // keyword is for inner state
  }, [keyword]);

  const onRefineClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => setAnchorEl(null), 0); // prevet calling it before onClickHandler
    sendEvent({ action: 'Search Keyword', category: 'engagement', label: keyword });
    setTimeout(() => (
      history.push(`/search?query=${keyword}`)
    ), 0);
  }, [keyword, pathname]);

  const onKeyDownHandler = useCallback(e => {
    if (e.key === 'Enter' || e.key === 'enter') {
      onRefineClick(e);
    }
  }, [onRefineClick]);

  /* -------------------------------- rendering ------------------------------- */
  return (
    <Root
      data-cy="searchbox"
      data-testid="searchbox"
      navigation={navigation}
      position={position}
      isActive={open}
      onClick={onClickHandler}
    >
      <Input
        inputRef={inputRef}
        placeholder={open ? 'Try a place name' : placeholder}
        value={keyword}
        inputProps={{
          'aria-label': 'Search Interested Place',
        }}
        disableUnderline
        onChange={onInputChange}
        onKeyDown={onKeyDownHandler}
        classes={{ root: inputClasses.root }}
      />
      {
        keyword
          ? (
            <ClearIcon
              alt="clear text"
              overwritestyles={clearIconStyle}
              onClick={onClearTextClick}
            />
          ) : null
      }
      <SearchIcon
        keyword={keyword}
        alt="search-icon"
        overwritestyles={searchIconStyle}
        onClick={onRefineClick}
      />
      {
        disableList
          ? null
          : (
            <Popper
              placement="bottom-start"
              open={open}
              anchorEl={anchorEl}
              modifiers={popperConfig}
              transition
              style={{ zIndex: 1302 }}
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Fade {...TransitionProps} timeout={150}>
                    <Dropdown width={dropdownWidth} data-cy="searchbox-dropdown" data-testid="searchbox-dropdown">
                      <>{ listEl }</>
                    </Dropdown>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          )
      }
    </Root>
  );
};

SearchBox.propTypes = {
  navigation: PropTypes.bool,
  placeholder: PropTypes.string,
  listEl: PropTypes.node,
  selection: PropTypes.string,
  scrollIntoView: PropTypes.bool,
  position: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any),
  pathname: PropTypes.string,
  refine: PropTypes.func,
  disableList: PropTypes.bool,
};

SearchBox.defaultProps = {
  navigation: false,
  placeholder: 'Where are you going?',
  listEl: <></>,
  selection: '',
  scrollIntoView: false,
  position: POSITION.NAVIGATION,
  history: {
    push: () => {}
  },
  pathname: '',
  refine: () => {},
  disableList: false,
};

export default SearchBox;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SearchIcon } from 'components/StyledIcons/StyledIcons';
import { media } from '../../theme/styled-theme';

export const POSITION = {
  NAVIGATION: 'Navigation',
  HOME_BANNER: 'Home Banner',
  HOME_BANNER_BOTTOM: 'Home Banner Bottom',
};

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const PlaceHolder = styled.div`
  color: #8c9baa;
`;

/* eslint-disable indent */
const Root = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid ${props => props.isActive ? '#00b33b' : '#b4b4b4'};
  height: ${props => props.navigation ? '2.625rem' : '3rem'};
  padding: 0.875rem 3.25rem 0.875rem 1rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: ${props => {
    const defaultShadow = props.navigation ? '1px 2px 8px 1px rgba(0,0,0,0.12)' : '';
    return props.isActive ? `${defaultShadow}, inset 1px 1px 3px 0 rgba(4, 132, 75, 0.3)` : defaultShadow;
  }};

  ${media.forPhoneOnly`
    padding: 0.875rem 1rem;
  `}

  ${media.forTabletLandscapeUp`
    max-width: ${
      props => props.position === POSITION.HOME_BANNER
      || props.position === POSITION.NAVIGATION ? '360px' : 'inherit'
    };
  `}
`;
/* eslint-enable indent */

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const SearchBox = props => {
  const {
    navigation,
    placeholder,
    position,
  } = props;

  const searchIconStyle = useMemo(() => ({
    root: {
      display: 'flex',
      width: '1.5rem',
      height: '1.5rem',
      color: '#343434',
      right: '0.5rem',
      position: 'absolute',
      background: 'white'
    }
  }), []);

  /* -------------------------------- rendering ------------------------------- */
  return (
    <Root
      data-testid="searchbox"
      navigation={navigation}
      position={position}
    >
      <PlaceHolder>{ placeholder }</PlaceHolder>
      <SearchIcon
        alt="search-icon"
        overwritestyles={searchIconStyle}
      />
    </Root>
  );
};

SearchBox.propTypes = {
  navigation: PropTypes.bool,
  placeholder: PropTypes.string,
  position: PropTypes.string,
};

SearchBox.defaultProps = {
  navigation: false,
  placeholder: 'Where are you going?',
  position: POSITION.NAVIGATION,
};

export default SearchBox;

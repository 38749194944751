import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../theme/styled-theme';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  border-radius: 4px;
  padding: 2rem 1rem;
  text-align: center;
  color: ${props => props.reverse ? 'white' : '#343434'};
  font-family: ${props => props.theme.fontSourceSansPro};
  background-color: ${props => props.backgroundColor};

  ${media.forPhoneOnly`
    border-radius: 0;
  `}
`;

const Title = styled.h3`
  font-size: 1.5rem;
  margin: 0.5rem;
`;

const Description = styled.p`
  ${media.forPhoneOnly`
    font-size: 0.875rem;
    margin: 0;
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const FullWidthInformationBanner = props => {
  const {
    title, description, backgroundColor, reverse
  } = props;

  return (
    <Root
      backgroundColor={backgroundColor}
      reverse={reverse}
    >
      <Title>{ title }</Title>
      <Description>{ description }</Description>
    </Root>
  );
};

FullWidthInformationBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  reverse: PropTypes.bool,
};

FullWidthInformationBanner.defaultProps = {
  title: '',
  description: '',
  backgroundColor: '#CCECFF',
  reverse: false,
};

export default FullWidthInformationBanner;

/* eslint-disable no-unused-expressions */
import React, {
  memo, useRef, useState, useEffect, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import replaceS3WithCDN from 'utils/replaceS3WithCDN';
import moment from 'moment-timezone';
import isEmpty from 'lodash.isempty';
import debounce from 'lodash.debounce';
import raf from 'raf';
import { MobileScrollableWrap, MobileScrollableItem } from './ReviewScrollBarHorizontal.styled';
import { LeftReviewScrollButton, RightReviewScrollButton } from './ReviewScrollButton';

/* -------------------------------------------------------------------------- */
/*                              styled-components                             */
/* -------------------------------------------------------------------------- */

const Root = styled.div`
  position: relative;
`;

const LeftButtonWrap = styled.button`
  cursor: pointer;
  position: absolute;
  background: transparent;
  border: none !important;
  border-radius: 50%;
  top: 40%;
  left: -2.5%;
  opacity: ${props => props.visible ? '1' : '0'};
  transition: opacity 0.25s ease-in-out;
`;

const RightButtonWrap = styled(LeftButtonWrap)`
  left: 98.5%;
`;

const ReviewScrollableItem = styled(MobileScrollableItem)`
  margin: 0 0 8px;
`;

/* -------------------------------------------------------------------------- */
/*                              React component                               */
/* -------------------------------------------------------------------------- */
const Review = memo(_props => {
  const {
    guest,
    created_at: _createAt,
    overall_rating: overallRating,
    host = {},
    content,
    renderCard,
    key
  } = _props;
  const {
    first_name: userName,
    profile_photo_thumbnail_url: _userPhoto
  } = guest;
  const {
    first_name: firstName = '',
    last_name: lastName = '',
    profile_photo_thumbnail_url: _driverPhoto = ''
  } = host;

  const rating = parseFloat(overallRating);
  const createAt = moment(_createAt).format('MMMM YYYY');
  const userPhoto = replaceS3WithCDN(_userPhoto);
  const driverPhoto = _driverPhoto && replaceS3WithCDN(_driverPhoto);
  const lastNameAbbr = lastName && lastName.length ? `${lastName[0]}.` : '';

  const data = {
    user: {
      name: userName,
      photo: userPhoto
    },
    review: content,
    createAt,
    ...(!isEmpty(host) ? {
      driver: {
        name: `${firstName} ${lastNameAbbr}`,
        photo: driverPhoto,
        rating
      }
    } : {
      rating
    })
  };

  return (
    <ReviewScrollableItem key={key}>
      { renderCard(data) }
    </ReviewScrollableItem>
  );
});

const ReviewScrollBarHorizontal = memo(props => {
  const { reviews = [], renderCard } = props;
  const scrollBarRef = useRef();
  const [refBounding, setRefBounding] = useState([0, 0, 0]);
  const [scrollLeft, scrollWidth, clientWidth] = refBounding;
  const leftButtonClick = useCallback(() => { raf(() => { scrollBarRef.current.scrollLeft -= 295; }); }, []);
  const rightButtonClick = useCallback(() => { raf(() => { scrollBarRef.current.scrollLeft += 295; }); }, []);

  const isLeftVisible = useMemo(
    () => scrollLeft !== 0,
    [scrollLeft, scrollWidth, clientWidth]
  );
  const isRightVisible = useMemo(
    () => scrollLeft !== (scrollWidth - clientWidth),
    [scrollLeft, scrollWidth, clientWidth]
  );

  const handleScroll = useCallback(
    debounce(() => {
      // After component mounted, set scrollWidth and clientWidth
      setRefBounding([
        scrollBarRef.current.scrollLeft,
        scrollBarRef.current.scrollWidth,
        scrollBarRef.current.clientWidth
      ]);
    }, 100),
    [debounce]
  );

  useEffect(() => {
    // After component mounted, set scrollWidth and clientWidth
    setRefBounding([
      scrollBarRef.current.scrollLeft,
      scrollBarRef.current.scrollWidth,
      scrollBarRef.current.clientWidth
    ]);

    // Bind scroll event listener
    scrollBarRef.current.addEventListener('scroll', handleScroll);

    return () => scrollBarRef.current.removeEventListener('scroll', handleScroll);
  }, []);

  const getReviews = useCallback(_reviews => _reviews && _reviews.map((review, index) => (
    <Review
      renderCard={renderCard}
      index={index}
      key={`review-${index}`}
      {...review}
    />
  )), []);

  return (
    <Root>
      <MobileScrollableWrap ref={scrollBarRef} key="UserReviews Scrollable">
        { getReviews(reviews) }
      </MobileScrollableWrap>
      <LeftButtonWrap
        onClick={leftButtonClick}
        visible={isLeftVisible}
      >
        <LeftReviewScrollButton />
      </LeftButtonWrap>
      <RightButtonWrap
        onClick={rightButtonClick}
        visible={isRightVisible}
      >
        <RightReviewScrollButton />
      </RightButtonWrap>
    </Root>
  );
});

ReviewScrollBarHorizontal.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderCard: PropTypes.func,
};

ReviewScrollBarHorizontal.defaultProps = {
  renderCard: () => {},
};

export default ReviewScrollBarHorizontal;

import React, {
  useEffect, useState, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { sendEvent } from 'utils/gtag';
import toLowerCase from 'lodash.lowercase';
import { useTranslation } from 'react-i18next';
import { SearchIcon, ArrowBackIcon } from 'components/StyledIcons/StyledIcons';
import MobileNavLinks from './MobileNavLinks';
import KeyboardArrowDownIcon from '../../assets/icon-dropdown.svg';
import { media } from '../../theme/styled-theme';
import SearchBox from '../SearchBox/Loadable';
import { POSITION as searchBoxPosition } from '../SearchBox/SearchBox';
import DropDownList, { PLACE_TYPE } from '../SearchBox/DropDownList';
import logoFull from '../../assets/ownrides-logo.svg';

const SEARCH_HASH = '#search-dialog';

export const countryList = [{
  name: 'Taiwan',
  places: [{
    type: PLACE_TYPE.COUNTRY,
    name: 'Taiwan',
    country: 'Taiwan'
  }, {
    type: PLACE_TYPE.CITY,
    name: 'Taipei',
    country: 'Taiwan'
  }, {
    type: PLACE_TYPE.CITY,
    name: 'Taichung',
    country: 'Taiwan'
  }, {
    type: PLACE_TYPE.CITY,
    name: 'Kaohsiung',
    country: 'Taiwan',
  }, {
    type: PLACE_TYPE.CITY,
    name: 'Hualien',
    country: 'Taiwan'
  }]
}, {
  name: 'Thailand',
  places: [{
    type: PLACE_TYPE.COUNTRY,
    name: 'Thailand',
    country: 'Thailand'
  }, {
    type: PLACE_TYPE.CITY,
    name: 'Bangkok',
    country: 'Thailand'
  }]
}];

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const NavBar = styled.div`
  position: ${props => props.fixed ? 'fixed' : 'relative'};
  display: ${props => props.hidden ? 'none' : 'flex'};
  z-index: 1300;
  top: 0;
  left: 0;
  width: 100vw;
  font-family: ${props => props.theme.fontSourceSansPro};
  padding: 0.5rem 3rem;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 1px solid rgba(180, 180, 180, 0.4);
  ${media.forTabletPortraitOnly`
    padding: 0.5rem 1rem;
  `}

  ${media.forPhoneOnly`
    padding: 0.5rem 1rem;
  `}
`;

const Relative = styled.div`
  position: relative;
  z-index: 1309;
`;

const NavLogo = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
`;

const NavSearchBox = styled.div`
  display: flex;
  flex: 1;
  margin-left: 1.5rem;

  ${media.forPhoneOnly`
    display: none;
    margin-left: 2.5rem;
  `}
`;

const DisplayInMobile = styled.div`
  display: none;

  ${media.forPhoneOnly`
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0.5rem;
  `}
`;

const DesktopNav = styled.nav`
  display: flex;
  margin-left: auto;
  padding-left: 48px;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

const MobileNavTrigger = styled.div`
  display: none;

  ${media.forPhoneOnly`
    cursor: pointer;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 12rem;
    align-items: center;
    justify-content: flex-end;
    z-index: 1302;
  `}
`;

const Bracket = styled.span`
  display: flex;
  user-select: none;
  align-items: center;
  height: 1rem;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: ${props => props.active ? 'rotate(-180deg)' : 'rotate(0)'};
`;

const NavLink = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  color: #343434;
  font-weight: 700;

  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

const MobileSearchBoxWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const Navigation = props => {
  const { t } = useTranslation('new-version');
  const { history, location } = useReactRouter();
  const [selection, setSelection] = useState();
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [mobileSearchActive, setMobileSearchActive] = useState(false);
  const {
    toggleLoginDialog, accessToken, logout
  } = props;
  const { pathname, search } = location;
  let hash = '';
  if (typeof window !== 'undefined') {
    hash = window.document?.location?.hash || hash;
  }

  /* --------------------------- hooks, handlers and memos --------------------------- */
  const shouldBeHidden = useMemo(() => {
    if (/^\/booking\//.test(pathname) && !/^\/booking\/success/.test(pathname)) return true;
    if (/^\/a\//.test(pathname)) return true;

    return false;
  }, [pathname]);

  const shouldBeFixed = useMemo(() => {
    if (/^\/customize\/planner\//.test(pathname)) return false;
    if (/^\/itinerary\//.test(pathname)) return false;

    return true;
  }, [pathname]);

  const searchIconStyle = useMemo(() => ({
    root: {
      cursor: 'pointer',
      width: '2rem',
      height: '2rem',
      color: '#343434',
    }
  }), []);

  const arrowBackIconStyle = useMemo(() => ({
    root: {
      cursor: 'pointer',
      width: '2rem',
      height: '2rem',
      color: '#343434',
      marginRight: '0.5rem'
    }
  }), []);

  useEffect(() => {
    document.body.style.marginTop = shouldBeHidden || !shouldBeFixed ? 0 : '59px';
  }, [shouldBeHidden, shouldBeFixed]);

  useEffect(() => {
    setMobileSearchActive(false);
  }, [location]);

  useEffect(() => {
    if (hash !== SEARCH_HASH) setMobileSearchActive(false);
  }, [hash]);

  const onLoginDialogToggle = useCallback(
    (e, target) => {
      e.stopPropagation();
      e.preventDefault();
      toggleLoginDialog(target);
    }, [toggleLoginDialog]
  );

  const toggleFullScreenMenu = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (mobileNavActive) {
        sendEvent({ action: 'Close Mobile Navbar Menu', category: 'navbar', label: 'mobile' });
      } else {
        sendEvent({ action: 'Open Mobile Navbar Menu', category: 'navbar', label: 'mobile' });
      }

      setMobileNavActive(!mobileNavActive);
    },
    [mobileNavActive]
  );

  const openFullScreenSearch = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      history.push(`${pathname}${search}${SEARCH_HASH}`);
      sendEvent({ action: 'Open Mobile Search Menu', category: 'navbar', label: 'mobile' });
      setMobileSearchActive(true);
    },
    [pathname, search]
  );

  const closeFullScreenSearch = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      sendEvent({ action: 'Close Mobile Search Menu', category: 'navbar', label: 'mobile' });
      history.goBack();
      setMobileSearchActive(false);
    },
    []
  );

  const onSignupClick = useCallback(e => {
    onLoginDialogToggle(e, 'signup');
    sendEvent({ action: 'Click Sign Up', category: 'navbar', label: 'desktop' });
  }, []);

  const onLoginClick = useCallback(e => {
    onLoginDialogToggle(e, 'login');
    sendEvent({ action: 'Click Log In', category: 'navbar', label: 'desktop' });
  }, []);

  const onCustomizeClick = useCallback(() => {
    sendEvent({ action: 'Click Customize', category: 'navbar', label: 'desktop' });
  }, []);

  const onHelpClick = useCallback(() => {
    sendEvent({ action: 'Click Help', category: 'navbar', label: 'desktop' });
  }, []);

  const onLogoutClick = useCallback(() => {
    logout();
    sendEvent({ action: 'Click Log Out', category: 'navbar' });
  }, []);

  const onMytripClick = useCallback(() => {
    sendEvent({ action: 'Click My Trips', category: 'navbar' });
  }, []);

  const DropDownListEl = useMemo(() => (
    <DropDownList
      countries={countryList}
      onPlaceClick={({ name, type, country }) => {
        const _name = toLowerCase(name);
        const _country = toLowerCase(country);
        const uri = type === PLACE_TYPE.CITY ? `/search?cities=${_name}` : `/search?country=${_country}`;

        setSelection(name);
        sendEvent({ action: 'Click Searchbox Item', category: 'navigation', label: name });

        if (hash === SEARCH_HASH) {
          history.replace(uri);
          setMobileSearchActive(false);
        } else {
          history.push(uri);
        }
      }}
    />
  ), [countryList, hash]);

  /* -------------------------------- rendering ------------------------------- */
  return (
    <NavBar
      hidden={shouldBeHidden}
      fixed={shouldBeFixed}
    >
      <Relative>
        <NavLogo>
          <Link
            to="/"
            onClick={() => sendEvent({ action: 'Click Logo', category: 'navbar', label: 'desktop' })}
          >
            <picture>
              <source
                type="image/svg+xml"
                srcSet={logoFull}
              />
              <img src={logoFull} alt="OWNRIDES Logo" />
            </picture>
          </Link>
        </NavLogo>
        <MobileNavTrigger onClick={toggleFullScreenMenu}>
          <Bracket active={mobileNavActive}>
            <img src={KeyboardArrowDownIcon} alt="bracket" />
          </Bracket>
        </MobileNavTrigger>
      </Relative>
      <NavSearchBox>
        {
          pathname === '/' || mobileNavActive
            ? null
            : (
              <SearchBox
                selection={selection}
                listEl={DropDownListEl}
                placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
                position={searchBoxPosition.NAVIGATION}
                navigation
                history={history}
                pathname={pathname}
              />
            )
        }
      </NavSearchBox>

      <DisplayInMobile onClick={openFullScreenSearch}>
        <SearchIcon overwritestyles={searchIconStyle} />
      </DisplayInMobile>

      <DesktopNav>
        <NavLink>
          <Link
            to="/itinerary/customize"
            onClick={onCustomizeClick}
          >
            { t('NAVIGATION.CUSTOMIZE.LINK') }
          </Link>
        </NavLink>
        <NavLink>
          <Link
            to="/help"
            target="_blank"
            onClick={onHelpClick}
          >
            { t('NAVIGATION.HELP.LINK') }
          </Link>
        </NavLink>
        {
          accessToken
            ? (
              <>
                <NavLink>
                  <Link
                    to="/trips"
                    onClick={onMytripClick}
                  >
                    {t('NAVIGATION.MY_TRIPS.LINK')}
                  </Link>
                </NavLink>
                <NavLink onClick={onLogoutClick}>{t('NAVIGATION.LOG_OUT.LINK')}</NavLink>
              </>
            )
            : (
              <>
                <NavLink
                  onClick={onSignupClick}
                  onKeyDown={onSignupClick}
                >
                  {t('NAVIGATION.SIGN_UP.LINK')}
                </NavLink>
                <NavLink
                  onClick={onLoginClick}
                  onKeyDown={onLoginClick}
                >
                  {t('NAVIGATION.LOG_IN.LINK')}
                </NavLink>
              </>
            )
        }
      </DesktopNav>

      <Dialog
        id="mobile-search-menu"
        open={mobileSearchActive}
        onClose={closeFullScreenSearch}
        fullScreen
        fullWidth
        style={{ zIndex: 1310 }}
      >
        <MobileSearchBoxWrap>
          <ArrowBackIcon
            overwritestyles={arrowBackIconStyle}
            onClick={closeFullScreenSearch}
          />
          <SearchBox
            selection={selection}
            disableList
            placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
            position={searchBoxPosition.NAVIGATION}
            navigation
            history={history}
            pathname={pathname}
          />
        </MobileSearchBoxWrap>
        { DropDownListEl }
      </Dialog>

      <Dialog
        id="mobile-navigation-menu"
        open={mobileNavActive}
        onClose={toggleFullScreenMenu}
        fullScreen
        fullWidth
        style={{ zIndex: 1209 }}
      >
        <MobileNavLinks
          toggle={toggleFullScreenMenu}
          toggleLoginDialog={onLoginDialogToggle}
          accessToken={accessToken}
          logout={onLogoutClick}
        />
      </Dialog>
    </NavBar>
  );
};

Navigation.propTypes = {
  toggleLoginDialog: PropTypes.func,
  logout: PropTypes.func,
  accessToken: PropTypes.string,
};

Navigation.defaultProps = {
  toggleLoginDialog: () => {},
  accessToken: '',
  logout: () => {},
};

export default Navigation;

import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const LocalGuideReviewCardLoadable = Loadable({
  loader: () => import('./LocalGuideReviewCard')
    .then(module => module.default)
    .catch(e => { console.log(e); if (window) window.location.reload(); }),
  loading: () => <div key="LocalGuideReviewCardLoadableLoading"><Loading /></div>
});

export default LocalGuideReviewCardLoadable;

import {
  useState, useCallback
} from 'react';
import useReactRouter from 'use-react-router';
import algoliasearch from 'algoliasearch/lite';
import qs from 'query-string';
import raf from 'raf';

let algoliaSearchClient = {};

if (process.env.ALGOLIA_APP_ID && process.env.ALGPLIA_FND_KEY) {
  algoliaSearchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGPLIA_FND_KEY);
}

const DEBOUNCE_TIME = 700;

// no /search/[cities] && /search?queries
export const isSearchRoute = _pathname => !(/^\/search[\/]/.test(_pathname)) && (/^\/search/.test(_pathname));

export const createURL = ({ query, refinementList, page }) => {
  const routeState = {
    query: query ? encodeURIComponent(query) : '',
    cities: refinementList && refinementList['city.name'] && refinementList['city.name'].join('~'),
    days: refinementList?.days || '',
    country: refinementList && refinementList['country.name'] || '',
    page,
  };

  return `?${qs.stringify(routeState)}`;
};

export const searchStateToUrl = (pathname, searchState) => searchState
  ? `${pathname}${createURL(searchState)}`
  : '';

export const urlToSearchState = search => {
  const routeState = qs.parse(search.slice(1));

  return {
    query: routeState.query ? decodeURIComponent(routeState.query) : '',
    refinementList: {
      'city.name': routeState.cities && routeState.cities.split('~') || [],
      'country.name': routeState.country || '',
      days: routeState.days || ''
    },
    page: routeState.page || 1,
  };
};

const useSearchState = () => {
  const { location, history } = useReactRouter();
  const { pathname, search } = location || {};
  const [lastHref, setLastHref] = useState(pathname + search);
  const [searchState, setSearchState] = useState(() => urlToSearchState(search));
  const [debouncedSetState, setDebouncedSetState] = useState(null);
  const [query, setQuery] = useState(searchState.query);
  const onSearchStateChange = useCallback(_searchState => {
    if (!isSearchRoute(pathname)) return;
    // reset scroll to top
    raf(() => { window.scroll({ top: 0 }); });

    clearTimeout(debouncedSetState);

    setDebouncedSetState(setTimeout(() => {
      const href = searchStateToUrl(pathname, _searchState);

      if (lastHref !== href) {
        history.replace(href, _searchState);
      }

      setLastHref(href);
    }, DEBOUNCE_TIME));

    setSearchState(_searchState);
  }, [pathname, debouncedSetState, lastHref]);

  return {
    algoliaSearchClient,
    searchState,
    setSearchState,
    onSearchStateChange,
    setQuery,
    query
  };
};

export default useSearchState;

import React from 'react';
import styled from 'styled-components';
import { media } from 'theme/styled-theme';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */

const Circle = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 2px 4px 3px 2px rgba(0, 0, 0, 0.2);
  
  ${media.forPhoneOnly`
    display: none;
  `}

  ${media.forTabletPortraitOnly`
    display: none;
  `}
`;

const RightArrow = styled.div`
  position: absolute;
  top: 30%;
  left: 20%;
  width: 1rem;
  height: 1rem;
  transform: rotate(-45deg);
  border: solid #343434;
  border-width: 0 0.125rem 0.125rem 0;
  border-radius: 0 0.125rem 0 0.125rem;
`;

const LeftArrow = styled(RightArrow)`
  transform: rotate(135deg);
  left: 40%;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line react/prop-types
const ReviewScrollButton = ({ arrow }) => (
  <Circle>
    {arrow}
  </Circle>
);

const RightReviewScrollButton = () => <ReviewScrollButton arrow={<RightArrow />} />;
const LeftReviewScrollButton = () => <ReviewScrollButton arrow={<LeftArrow />} />;

export {
  RightReviewScrollButton,
  LeftReviewScrollButton
};

import React, {
  memo, useMemo, useCallback, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';

import { ArrowBackIcon } from 'components/StyledIcons/StyledIcons';
import ValuesBanner from 'components/ValuesBanner/Loadable';
import FullWidthInformationBanner from 'components/FullWidthInformationBanner/FullWidthInformationBanner';
import MediaSection from 'components/MediaSection/Loadable';
import PageSectionHeaders from 'components/PageSectionHeaders/Loadable';
import CityCard from 'components/CityCard/Loadable';
// import ItineraryCardResolver from 'components/ItineraryCard/WeakLoadable';
import ItineraryCard from 'components/ItineraryCard/Loadable';
import HomepageBottomSearch from 'components/HomepageBottomSearch/Loadable';
import HomepageCountryCard from 'components/HomepageCountryCard/Loadable';
import HomeLandingSection from 'components/HomeLandingSection/Loadable';
import { getPriceQuote } from 'components/PriceNumber/PriceNumber';
import StyledButton from 'components/MuiComponents/RoundedButton';
import ButtonWithRightBracket from 'components/ButtonWithRightBracket/Loadable';
import SearchBox from 'components/SearchBox/Loadable';
import DummySearchBox from 'components/SearchBox/DummySearchBox';
import { POSITION as searchBoxPosition } from 'components/SearchBox/SearchBox';
import { countryList } from 'components/Navigation/Navigation';
import DropDownList, { PLACE_TYPE } from 'components/SearchBox/DropDownList';
import toLowerCase from 'lodash.lowercase';
import replaceS3WithCDN from 'utils/replaceS3WithCDN';
import config from 'config';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { media, mediaMUI } from 'theme/styled-theme';
// import OptimizeContext from 'context/OptimizeContext';
// import { sendEvent, activateOptimize } from 'utils/gtag';
import { sendEvent } from 'utils/gtag';
import { MobileScrollableWrap, MobileScrollableItem } from 'components/ReviewScrollBarHorizontal/ReviewScrollBarHorizontal.styled';
import UserReviews from 'components/ReviewScrollBarHorizontal/ReviewScrollBarHorizontal';
import LocalGuideReviewCard from 'components/LocalGuideReviewCard/Loadable';

/* -------------------------------------------------------------------------- */
/*                              Reducer & Actions                             */
/* -------------------------------------------------------------------------- */
import recommendedCountriesReducer, * as recommendedCountriesActions from 'redux/modules/recommendedCountries';
import recommendedReviewsReducer, * as recommendedReviewsActions from 'redux/modules/recommendedReviews';

const { isLoaded: isReviewsLoaded, loadReviews } = recommendedReviewsActions;
const { isCountriesLoaded, loadCountries } = recommendedCountriesActions;

const cdnPrefix = `${config.cdnUrl}/misc/banners`;
const mobileBg = `${cdnPrefix}/mobile-landing-bg@2x.jpg`;
const tabletBg = `${cdnPrefix}/tablet-landing-bg@2x.jpg`;
const desktopBg = `${cdnPrefix}/desktop-landing-bg@2x.jpg`;

const SEARCH_HASH = '#search-dialog';

/* -------------------------------------------------------------------------- */
/*                              styled-components                             */
/* -------------------------------------------------------------------------- */

const StyledCountryWrap = styled.div`
  display: flex;
  margin: 0 -0.5rem 4.5rem;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

  ${media.forPhoneOnly`
    flex-wrap: wrap;
    margin-bottom: 1rem;
  `}

  ${media.forTabletPortraitOnly`
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;

const DesktopSearchBoxWrap = styled.div`
  display: flex;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

const MobileSearchBoxWrap = styled.div`
  display: none;
  ${media.forPhoneOnly`
    display: flex;
  `}
`;

const StyledCountryCard = styled.div`
  display: flex;
  flex: 2 0 50%;
  padding: 0 0.5rem 0;

  ${media.forPhoneOnly`
    flex: 1 0 100%;
    margin-bottom: 1rem;
  `}

  ${media.forTabletPortraitOnly`
    flex: 1 0 100%;
    margin-bottom: 1rem;
  `}
`;

const StyledItineariesWarp = styled.div`
  display: flex;
  flex: 2 0 50%;
  flex-wrap: wrap;

  ${media.forPhoneOnly`
    flex: 2 0 100%;
  `}
`;

const StyledItineraryCard = styled.div`
  flex: 1 0 50%;
  padding: 0 0.5rem 1rem;
`;

const SectionSpaceTop = styled.div`
  margin-top: 48px;
`;

const OverflowHidden = styled.div`
  overflow: hidden;
`;

const MobileDialogSearchBoxWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

/* -------------------------------------------------------------------------- */
/*                        Rendering Function Components                       */
/* -------------------------------------------------------------------------- */
// eslint-disable-next-line react/prop-types
const Cities = memo(({ cities = [], country: countryName }) => (
  <MobileScrollableWrap>
    {cities && cities.map((city, index) => {
      const {
        cover_photo_thumbnail_url: photo,
        name,
        country,
        overall_rating: overallRating,
      } = city;
      const citiesCountryName = country?.name;
      const currency = country?.currency;

      // filter countryName if it is set
      if (countryName && citiesCountryName !== countryName) return null;

      const data = {
        name,
        photos: [replaceS3WithCDN(photo)],
        currency: currency.toUpperCase(),
        overallRating
      };

      return (
        <MobileScrollableItem key={`city-${index}`}>
          <Link
            to={`/search/${name}`}
            onClick={() => {
              sendEvent({ action: 'Click City Card on Home', category: 'navigation', label: name });
            }}
          >
            <CityCard city={data} />
          </Link>
        </MobileScrollableItem>
      );
    })}
  </MobileScrollableWrap>
));

const Itineraries = memo(({
  // eslint-disable-next-line react/prop-types
  itineraries = [], country, currency
}) => {
  // const ItineraryCard = ItineraryCardResolver();

  return (
    <>
      {itineraries.map((itinerary, index) => {
        const {
          name,
          photo: { image_url: photo },
          multiple_days: multipleDays,
          duration: totalHours,
          city: { name: city },
          bookings_count: bookings,
          price_per_person_minimum: price,
          profit_margin: margin,
          slug
        } = itinerary;
        const days = multipleDays ? totalHours : 1;
        const priceFrom = getPriceQuote({ price, margin });

        const data = {
          name,
          totalHours,
          days,
          priceFrom,
          city,
          currency,
          country,
          bookings,
          photo: replaceS3WithCDN(photo)
        };

        return (
          <StyledItineraryCard key={`itinerary-${index}`}>
            <Link
              to={`/itinerary/${slug}`}
              onClick={() => {
                sendEvent({ action: 'Click Itinerary Card on Home', category: 'navigation', label: name });
              }}
            >
              <ItineraryCard {...data} />
            </Link>
          </StyledItineraryCard>
        );
      })}
    </>
  );
});

const CountriesSection = memo(({
  // eslint-disable-next-line react/prop-types
  countries = [], t, buttonStyles
}) => (
  countries.map((country, index) => {
    const {
      name,
      description,
      cover_photo_url: _photo,
      itineraries,
      currency
    } = country;

    const onClickHandler = useCallback(e => {
      e.stopPropagation();
      sendEvent({ action: 'Click See More Tour Action Button', category: 'navigation', label: name });
    }, [name]);

    const photo = replaceS3WithCDN(_photo);
    const callToActionText = t('HOME.TOURS.ACTION_BUTTON');

    return (
      <>
        <StyledCountryWrap key={`country-section-${index}`} reverse={index % 2}>
          <StyledCountryCard>
            <HomepageCountryCard
              country={{
                photo,
                name,
                description
              }}
              reverse={index % 2}
              backgroundColor={index % 2 ? '#26ae8b' : '#f9db8b'}
              callToActionText={callToActionText}
            />
          </StyledCountryCard>
          <StyledItineariesWarp>
            <Itineraries
              itineraries={itineraries}
              country={name}
              currency={currency}
              area={index % 2 ? 'left' : 'right'}
            />
          </StyledItineariesWarp>
        </StyledCountryWrap>

        <Link
          to={`/country/${name}`}
          onClick={onClickHandler}
        >
          <StyledButton
            variant="outlined"
            overwritestyles={buttonStyles}
            fullWidth
          >
            {callToActionText}
          </StyledButton>
        </Link>
      </>
    );
  })
));

// eslint-disable-next-line react/prop-types
const RenderHelmet = memo(({ t }) => (
  <>
    <Helmet title={t('meta:META.HOME.TITLE')} />
    <Helmet
      meta={[
        {
          name: 'description',
          content: t('meta:META.HOME.DESCRIPTION')
        }
      ]}
    />
  </>
));

/* -------------------------------------------------------------------------- */
/*                               Main Component                               */
/* -------------------------------------------------------------------------- */
const NewHome = props => {
  const {
    countries = [],
    cities,
    reviews,
    t,
  } = props;
  const { history, location } = useReactRouter();
  const [mobileSearchActive, setMobileSearchActive] = useState(false);
  const { pathname, search } = location;
  const infoTitle = t('COMMON.KEY_VALUE.TITLE');
  const infoDescription = t('COMMON.KEY_VALUE.DESCRIPTION');
  const matchePhone = useMediaQuery(mediaMUI.forPhoneOnly);
  let hash = '';
  if (typeof window !== 'undefined') {
    hash = window.document?.location?.hash || hash;
  }

  const buttonStyles = useMemo(() => ({
    root: {
      display: matchePhone ? 'flex' : 'none',
      width: '100%',
      margin: '0.5rem 0 3rem',
      color: '#00b33b',
      border: '1px solid #b4b4b4'
    }
  }), [matchePhone]);

  const arrowBackIconStyle = useMemo(() => ({
    root: {
      cursor: 'pointer',
      width: '2rem',
      height: '2rem',
      color: '#343434',
      marginRight: '0.5rem'
    }
  }), []);

  const openFullScreenSearch = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      history.push(`${pathname}${search}${SEARCH_HASH}`);
      sendEvent({ action: 'Open Mobile Search Menu', category: 'navbar', label: 'mobile' });
      setMobileSearchActive(true);
    },
    [pathname, search, matchePhone]
  );

  const closeFullScreenSearch = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      sendEvent({ action: 'Close Mobile Search Menu', category: 'navbar', label: 'mobile' });
      history.goBack();
      setMobileSearchActive(false);
    },
    []
  );

  const DropDownListEl = useMemo(() => (
    <DropDownList
      countries={countryList}
      onPlaceClick={({ name, type, country }) => {
        const _country = toLowerCase(country);
        const _name = toLowerCase(name);
        const uri = type === PLACE_TYPE.CITY ? `/search?cities=${_name}` : `/search?country=${_country}`;

        sendEvent({ action: 'Click Searchbox Item', category: 'navigation', label: name });

        if (hash === SEARCH_HASH) {
          history.replace(uri);
          setMobileSearchActive(false);
        } else {
          history.push(uri);
        }
      }}
    />
  ), [countryList]);

  useEffect(() => {
    if (hash !== SEARCH_HASH) setMobileSearchActive(false);
  }, [hash]);

  useEffect(() => {
    setMobileSearchActive(false);
  }, [location]);

  const onDesktopReviewButtonClick = useCallback(e => {
    e.stopPropagation();
    sendEvent({ action: 'Click Reviews Action Button on Home', category: 'navigation', label: 'desktop header' });
  }, []);
  const onMobileReviewButtonClick = useCallback(e => {
    e.stopPropagation();
    sendEvent({ action: 'Click Reviews Action Button on Home', category: 'navigation', label: 'mobile' });
  }, []);
  const renderCard = useCallback(data => <LocalGuideReviewCard {...data} />, []);

  return (
    <OverflowHidden>
      <RenderHelmet t={t} />
      <HomeLandingSection
        mobilePhoto={mobileBg}
        tabletPhoto={tabletBg}
        desktopPhoto={desktopBg}
      >
        <MobileSearchBoxWrap onClick={openFullScreenSearch}>
          <DummySearchBox
            position={searchBoxPosition.HOME_BANNER}
            placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
          />
        </MobileSearchBoxWrap>
        <DesktopSearchBoxWrap>
          <SearchBox
            listEl={DropDownListEl}
            position={searchBoxPosition.HOME_BANNER}
            placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
            scrollIntoView
            history={history}
            pathname={pathname}
          />
        </DesktopSearchBoxWrap>
      </HomeLandingSection>
      <div className="container">
        <SectionSpaceTop>
          <ValuesBanner homepage />
        </SectionSpaceTop>

        <PageSectionHeaders
          title={t('HOME.TOURS.HEADING')}
          subtitle="Private tours with driver guides. Safe travel post-COVID"
        />

        <CountriesSection
          buttonStyles={buttonStyles}
          countries={countries}
          history={history}
          t={t}
        />

        <SectionSpaceTop>
          <FullWidthInformationBanner
            title={infoTitle}
            description={infoDescription}
          />
        </SectionSpaceTop>

        <PageSectionHeaders title={t('HOME.DESTINATIONS.HEADING')} />
        <Cities cities={cities} />

        <PageSectionHeaders
          title={t('HOME.REVIEWS.HEADING')}
          actionButtonEl={(
            <Link to="/drivers/reviews" onClick={onDesktopReviewButtonClick}>
              <ButtonWithRightBracket>
                {t('COMMON.REVIEWS.ACTION_BUTTON')}
              </ButtonWithRightBracket>
            </Link>
          )}
        />
        <UserReviews
          reviews={reviews}
          renderCard={renderCard}
        />
        <Link to="/drivers/reviews" onClick={onMobileReviewButtonClick}>
          <StyledButton
            variant="outlined"
            overwritestyles={buttonStyles}
            fullWidth
          >
            {t('COMMON.REVIEWS.ACTION_BUTTON')}
          </StyledButton>
        </Link>

        <SectionSpaceTop>
          <HomepageBottomSearch>
            <MobileSearchBoxWrap onClick={openFullScreenSearch}>
              <DummySearchBox
                position={searchBoxPosition.HOME_BANNER_BOTTOM}
                placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
              />
            </MobileSearchBoxWrap>
            <DesktopSearchBoxWrap>
              <SearchBox
                listEl={DropDownListEl}
                position={searchBoxPosition.HOME_BANNER_BOTTOM}
                placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
                scrollIntoView
                history={history}
                pathname={pathname}
              />
            </DesktopSearchBoxWrap>
          </HomepageBottomSearch>
        </SectionSpaceTop>
      </div>

      <div className="container">
        <MediaSection />
      </div>

      <Dialog
        id="mobile-search-menu"
        open={mobileSearchActive}
        onClose={closeFullScreenSearch}
        fullScreen
        fullWidth
        style={{ zIndex: 1310 }}
      >
        <MobileDialogSearchBoxWrap>
          <ArrowBackIcon
            overwritestyles={arrowBackIconStyle}
            onClick={closeFullScreenSearch}
          />
          <SearchBox
            disableList
            placeholder={t('SEARCHBOX.CALL_TO_ACTION_TEXT')}
            position={searchBoxPosition.NAVIGATION}
            navigation
            history={history}
            pathname={pathname}
          />
        </MobileDialogSearchBoxWrap>
        { DropDownListEl }
      </Dialog>
    </OverflowHidden>
  );
};

NewHome.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.any),
  cities: PropTypes.arrayOf(PropTypes.any),
  reviews: PropTypes.arrayOf(PropTypes.any),
  t: PropTypes.func
};

NewHome.defaultProps = {
  countries: [],
  reviews: [],
  cities: [],
  t: () => {}
};

export default compose(
  provideHooks({
    inject: ({ store }) => store.inject({
      recommendedReviews: recommendedReviewsReducer,
      recommendedCountries: recommendedCountriesReducer
    }),
    fetch: async ({ store: { dispatch, getState } }) => {
      if (!isReviewsLoaded(getState())) {
        await dispatch(loadReviews()).catch(() => null);
      }
      if (!isCountriesLoaded(getState())) {
        await dispatch(loadCountries()).catch(() => null);
      }
    }
  }),
  connect(state => ({
    countries: state.recommendedCountries?.countries,
    cities: state.cities?.cities,
    reviews: state.recommendedReviews?.reviews
  })),
  withTranslation(['new-version', 'meta'])
)(NewHome);

import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sendEvent } from 'utils/gtag';

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;
`;

const MobileNavLink = styled.div`
  cursor: pointer;
  flex: 1;
  padding: 0.75rem 0;
  text-align: center;
  color: #595959;
`;

const Separator = styled.div`
  height: 1px;
  width: 225px;
  background:  rgba(180, 180, 180, 0.4);
  margin: 1.25rem auto;
`;

const MobileNavLinks = memo(({
  logout, toggle, toggleLoginDialog, accessToken
}) => {
  const { history } = useReactRouter();
  const { t } = useTranslation('new-version');
  const onMobileLinkClick = useCallback((e, link = '/', name) => {
    sendEvent({ action: `Click ${name}`, category: 'navbar', label: 'mobile' });
    toggle(e);
    history.push(link);
  }, []);

  const onSignupClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      toggle(e);
      toggleLoginDialog(e, 'signup');
      sendEvent({ action: 'Click Sign Up', category: 'navbar', label: 'mobile' });
    },
    []
  );

  const onLoginClick = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      toggle(e);
      toggleLoginDialog(e, 'login');
      sendEvent({ action: 'Click Log In', category: 'navbar', label: 'mobile' });
    },
    []
  );

  const onHelpClick = useCallback(
    e => {
      e.stopPropagation();
      sendEvent({ action: 'Click Help', category: 'navbar', label: 'mobile' });
    },
    []
  );

  return (
    <>
      <MobileNav>
        <MobileNavLink>
          <Link
            to="/"
            onClick={e => onMobileLinkClick(e, '/', 'Home')}
          >
            Home
          </Link>
        </MobileNavLink>

        <Separator />

        <MobileNavLink>
          <Link
            to="/help"
            target="_blank"
            onClick={onHelpClick}
          >
            { t('NAVIGATION.HELP.LINK') }
          </Link>
        </MobileNavLink>
        <MobileNavLink>
          <Link
            to="/itinerary/customize"
            onClick={e => onMobileLinkClick(e, '/itinerary/customize', 'Customize')}
          >
            { t('NAVIGATION.CUSTOMIZE.LINK') }
          </Link>
        </MobileNavLink>
        <MobileNavLink>
          <Link
            to="/drivers/reviews"
            onClick={e => onMobileLinkClick(e, '/drivers/reviews', 'Review')}
          >
            Reviews
          </Link>
        </MobileNavLink>
        {
          accessToken
            ? (
              <MobileNavLink>
                <Link
                  to="/trips"
                  onClick={e => onMobileLinkClick(e, '/trips')}
                >
                  {t('NAVIGATION.MY_TRIPS.LINK')}
                </Link>
              </MobileNavLink>
            )
            : null
        }

        <Separator />

        {
          accessToken
            ? (
              <>
                <MobileNavLink onClick={logout}>
                  {t('NAVIGATION.LOG_OUT.LINK')}
                </MobileNavLink>
              </>
            )
            : (
              <>
                <MobileNavLink
                  onClick={onSignupClick}
                  onKeyDown={onSignupClick}
                >
                  {t('NAVIGATION.SIGN_UP.LINK')}
                </MobileNavLink>
                <MobileNavLink
                  onClick={onLoginClick}
                  onKeyDown={onLoginClick}
                >
                  {t('NAVIGATION.LOG_IN.LINK')}
                </MobileNavLink>
              </>
            )
        }
      </MobileNav>
    </>
  );
});

MobileNavLinks.propTypes = {
  toggleLoginDialog: PropTypes.func,
  toggle: PropTypes.func,
  accessToken: PropTypes.string,
  logout: PropTypes.func,
};

MobileNavLinks.defaultProps = {
  toggle: () => {},
  logout: () => {},
  toggleLoginDialog: () => {},
  accessToken: '',
};

export default MobileNavLinks;

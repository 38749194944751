import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiPlaceIcon from '@material-ui/icons/PlaceSharp';
import MuiGlobeIcon from '@material-ui/icons/LanguageSharp';
export const PLACE_TYPE = {
  COUNTRY: 'COUNTRY',
  CITY: 'CITY',
  PLACE: 'PLACE',
};

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const List = styled.ul`
  cursor: pointer;
  font-family: ${props => props.theme.fontSourceSansPro};
  margin: 0;
  padding: 0;
  color: #343434;
  user-select: none;
  list-style: none;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.75rem 1rem;
  background-color: #ffffff;

  &:hover {
    background-color: rgba(0, 179, 59, 0.2);
  }
`;

const Header = styled.li`
  margin: 0;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  background: #F4F6F9;
  font-weight: 700;
  color: #343434;
  text-transform: uppercase;
`;

const PlaceIcon = styled(MuiPlaceIcon)`
  font-size: 1rem !important;
  color: #343434;
  margin: 0 0.5rem 0 0;
  padding: 0;
`;

const GlobeIcon = styled(MuiGlobeIcon)`
  font-size: 1rem !important;
  color: #343434;
  margin: 0 0.5rem 0 0;
  padding: 0;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const DropDownList = ({ countries, onPlaceClick }) => {
  const onPlaceClickHandler = useCallback(
    (e, name, type, country) => {
      onPlaceClick({ name, type, country });
    },
    [onPlaceClick],
  );

  const countryList = useMemo(() => {
    const getPlaceList = placeList => (
      placeList.map(({ name, type, country }) => (
        <Item key={`${name}-${type}`} onClick={e => onPlaceClickHandler(e, name, type, country)}>
          { type === PLACE_TYPE.CITY ? <PlaceIcon /> : <GlobeIcon /> }
          {name}
          { type === PLACE_TYPE.CITY ? `・${country}` : '' }
        </Item>
      ))
    );

    return (
      countries.map(({ name: countryName, places }) => (
        <React.Fragment>
          <Header key={`header-${countryName}`}>{ countryName }</Header>
          { getPlaceList(places) }
        </React.Fragment>
      ))
    );
  }, [countries, onPlaceClickHandler]);

  return (
    <List>
      { countryList }
    </List>
  );
};

DropDownList.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.any),
  onPlaceClick: PropTypes.func,
};

DropDownList.defaultProps = {
  countries: [],
  onPlaceClick: () => {}
};

export default DropDownList;

const LOAD = 'COUNTRIES/RECOMMENDED/LOAD';
const LOAD_SUCCESS = 'COUNTRIES/RECOMMENDED/LOAD_SUCCESS';
const LOAD_FAIL = 'COUNTRIES/RECOMMENDED/LOAD_FAIL';

const initialState = () => ({
  loaded: false,
  loading: false,
  countries: [],
});

export default function reducer(state = initialState(), action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        countries: action.result?.countries,
        error: null
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        tours: null,
        error: typeof action.error === 'string' ? action.error : 'Error'
      };
    default:
      return state;
  }
}

export function isCountriesLoaded(globalState) {
  return globalState?.recommendedCountries?.loaded;
}

export function loadCountries() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/countries/recommended'),
  };
}
